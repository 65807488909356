import { useContext, useState } from 'react'
import useApi from './useApi'
import { AuthContext } from '../contexts/AuthContext'
import apiConfig from '../config/apiConfig'
import { ResourceContext } from '../contexts/ResourceContext'

interface ReturnProps {
  initiateLogin: () => Promise<void>
  signOut: () => Promise<void>
  loading: boolean
  error: string | null
}

export const useAuth = (): ReturnProps => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const { updateAuthState } = useContext(AuthContext)
  const { post, endpoints } = useApi()
  const { setDeviceId, setAgreedTermsVersion, setDemo } = useContext(AuthContext)
  const { resetResources } = useContext(ResourceContext)

  const initiateLogin = async (): Promise<void> => {
    try {
      sessionStorage.setItem('authStartTime', Date.now().toString())
      const encodedRedirectUrl = encodeURIComponent(apiConfig.redirectUrl)
      window.location.href = `${apiConfig.apiUrl}/login/authorize?returnUrl=${encodedRedirectUrl}`
    } catch (error) {
      setError(error instanceof Error ? error.message : String(error))
      throw error
    }
  }

  const signOut = async (): Promise<void> => {
    try {
      setLoading(true)
      await post(endpoints.logoutPath)
      resetResources()
      updateAuthState(false)
      setDeviceId(null)
      setAgreedTermsVersion(null)
      setDemo(false)
    } catch (error) {
      setError(error instanceof Error ? error.message : String(error))
    } finally {
      setLoading(false)
    }
  }

  return {
    initiateLogin,
    signOut,
    loading,
    error
  }
}

export default useAuth
