import React, { ReactElement, useEffect, useMemo } from 'react'
import PageTitle from '../components/PageTitle'
import Notification from '../components/Notification'
import useLocalization from '../hooks/useLocalization'
import useSalaries from '../hooks/useSalaries'
import useAccount from '../hooks/useAccount'
import Loader from '../components/Loader'
import SalarySummaryList from '../components/SalarySummaryList'
import SalaryContactForm from '../components/SalaryContactForm'
import useAnalytics from '../hooks/useAnalytics'
import useUser from '../hooks/useUser'
import SalaryPeriodSummary from '../types/SalaryPeriodSummary'
import SalaryYearSummary from '../types/SalaryYearSummary'
import Icon from '../components/Icon'
import SalaryArchiveList from '../components/SalaryArchiveList'

const Salaries = (): ReactElement => {
  const { logPageView } = useAnalytics()
  const { userApps, getUserApps, loading: loadingUserApps } = useUser()
  const { employments, getAccount, loading: loadingAccount } = useAccount()
  const { error, getSummaries, yearSummaries, periodSummaries, loading: loadingSalaries } = useSalaries()
  const { t } = useLocalization()

  useEffect(() => {
    logPageView('Salaries')
    const fetchData = async (): Promise<void> => {
      const accountData = await getAccount()
      if (accountData != null) {
        await getUserApps(accountData)
        await getSummaries()
      }
    }
    void fetchData()
  }, [logPageView, getAccount, getUserApps, getSummaries])

  const allowedPeriodSummaries = useMemo<SalaryPeriodSummary[]>(() => (
    (periodSummaries ?? []).filter(summary => (
      userApps[summary.CompanyId]?.App3
    ))
  ), [periodSummaries, userApps])

  const allowedYearSummaries = useMemo<SalaryYearSummary[]>(() => (
    (yearSummaries ?? []).filter(summary => (
      summary.CompanyId === null || userApps[summary.CompanyId]?.App3
    ))
  ), [yearSummaries, userApps])

  if (loadingAccount || loadingSalaries || loadingUserApps) {
    return <Loader />
  }

  const hasEmployment = employments.length > 0 &&
    employments.some(e => e.CompanyId !== null && userApps[e.CompanyId]?.App3 === true)

  const hasSalaries = allowedYearSummaries.length > 0 || allowedPeriodSummaries.length > 0

  return (
    <div className='Salaries'>
      <PageTitle icon={<Icon route='salaries' />}>
        {hasEmployment && t('mainSalary.title')}
        {!hasEmployment && t('mainSalary.noSalaryTitle')}
      </PageTitle>
      <div className='contentContainer'>
        <div className='contentColumn'>
          {error !== null && <Notification variant='danger'>{error}</Notification>}
          {error === null && !hasSalaries && hasEmployment && (
            <div className='noSalariesSent'>
              {t('mainSalary.noSalarySent')}
            </div>
          )}
          {error === null && !hasEmployment && (
            <div className='noEmployment'>
              <p>{t('mainSalary.noSalary')}</p>
              <SalaryContactForm />
            </div>
          )}
          {error === null && hasEmployment && hasSalaries && (
            <SalarySummaryList
              periodSummaries={allowedPeriodSummaries}
              yearSummaries={allowedYearSummaries}
            />
          )}
        </div>
        <div className='contentColumn'>
          {error === null && <SalaryArchiveList yearSummaries={yearSummaries} />}
        </div>
      </div>
    </div>
  )
}
export default Salaries
