import { ReactElement } from 'react'
import InfoRow from './InfoRow'
import SalaryYearSummary from '../types/SalaryYearSummary'
import useLocalization from '../hooks/useLocalization'
import useFormatter from '../hooks/useFormatter'

interface Props {
  yearSummaries: SalaryYearSummary[] | null
}

const SalaryArchiveList = ({ yearSummaries }: Props): ReactElement => {
  const { t } = useLocalization()
  const { formatCurrency } = useFormatter()

  const resolveYearSummaries = (): Array<{ year: string, total: number }> => {
    if (yearSummaries === null || yearSummaries.length === 0) {
      return []
    }
    const summaries: Array<{ year: string, total: number }> = []
    for (const summary of yearSummaries) {
      const index = summaries.findIndex(i => i.year === summary.Year)
      if (index !== -1) {
        summaries[index].total += parseFloat(summary.Total)
      } else {
        summaries.push({ year: summary.Year, total: parseFloat(summary.Total) })
      }
    }
    return summaries.sort((a, b) =>
      parseInt(b.year, 10) - parseInt(a.year, 10)
    ).filter((item, i) => i !== 0)
  }

  return (
    <div className='SalaryArchiveList'>
      {resolveYearSummaries().length > 0 &&
        <InfoRow label={t('mainSalary.archive')} variant='title' />}
      {resolveYearSummaries().map((summary, i) => (
        <InfoRow
          id={`yearSummaryRow${i}`}
          key={`yearSummaryRow${i}`}
          label={summary.year}
          value={formatCurrency(summary.total)}
          linkTo={`/salaries/${summary.year}`}
        />
      ))}
    </div>
  )
}

export default SalaryArchiveList
