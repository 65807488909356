import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { Navigate, useLocation } from 'react-router-dom'
import useUser from '../hooks/useUser'
import useAccount from '../hooks/useAccount'
import useAnalytics from '../hooks/useAnalytics'
import Loader from '../components/Loader'

const Redirect = (): ReactElement => {
  const { logSignIn } = useAnalytics()
  const { getAccount } = useAccount()
  const { getTermsOfServiceStatus } = useUser()
  const { updateAuthState, setAgreedTermsVersion, setDemo, isDemo, termsAgreed } = useContext(AuthContext)
  const { search } = useLocation()
  const [redirectReady, setRedirectReady] = useState(false)

  useEffect(() => {
    const handleRedirect = async (): Promise<void> => {
      const params = new URLSearchParams(search)
      const state = params.get('state')
      setDemo(state === 'demo')
      updateAuthState(true)

      const account = await getAccount()
      if (account !== null) {
        logSignIn(account)
        if (!isDemo()) {
          const version = await getTermsOfServiceStatus()
          setAgreedTermsVersion(version)
        }
      }
      setRedirectReady(true)
    }
    void handleRedirect()
  }, [])

  if (redirectReady) {
    return <Navigate to={termsAgreed ? '/salaries' : '/agree_terms'} replace />
  }

  return (
    <div className='Redirect'>
      <Loader />
    </div>
  )
}

export default Redirect
