/* const session = {
  idleTimeLimitMinutes: parseInt(
    process.env.REACT_APP_SESSION_TIME_LIMIT !== undefined ? process.env.REACT_APP_SESSION_TIME_LIMIT : '5',
    10
  ), // Time after the last request before user will be signed out
  dialogShowTimeSeconds: 60, // The SessionTimeWatcher modal dialog will be shown X seconds before the session ends
  showCookieConsentDialog: process.env.REACT_APP_ENV !== 'test'
}
export default session
 */

const session = {
  idleTimeLimitMinutes: 5, // Time after the last request before user will be signed out
  dialogShowTimeSeconds: 60, // The SessionTimeWatcher modal dialog will be shown X seconds before the session ends
  showCookieConsentDialog: process.env.REACT_APP_ENV !== 'test'
}
export default session
