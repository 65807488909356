import React, { createContext, useState } from 'react'
import { FirebaseApp } from 'firebase/app'
import { Analytics, setUserProperties as fbSetUserProperties, setUserId } from 'firebase/analytics'
import useStorage from '../hooks/useStorage'

interface Context {
  app: FirebaseApp | null
  setApp: (app: FirebaseApp) => void
  userProperties: any
  setUserProperties: (analytics: Analytics, props: any, id: string) => void
  cookieConsent: boolean | null
  setCookieConsent: (consent: boolean) => void

}

const defaultValues = {
  app: null,
  setApp: () => {},
  userProperties: null,
  setUserProperties: () => {},
  cookieConsent: null,
  setCookieConsent: () => {}
}

export const AnalyticsContext = createContext<Context>(defaultValues)

export const AnalyticsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const storage = useStorage()
  const [app, setApp] = useState<FirebaseApp | null>(defaultValues.app)
  const [userProperties, setUserProperties] = useState<any>(defaultValues.userProperties)
  const [cookieConsent, setCookieConsent] = useState<boolean | null>(storage.getCookieConsent())

  const _setApp = (app: FirebaseApp): void => {
    setApp(app)
  }

  const _setUserProperties = (analytics: Analytics, props: any, id: string): void => {
    fbSetUserProperties(analytics, props)
    setUserId(analytics, id)
    setUserProperties(props)
  }

  const _setCookieConsent = (consent: boolean): void => {
    setCookieConsent(consent)
    storage.setCookieConsent(consent)
  }

  return (
    <AnalyticsContext.Provider
      value={{
        app,
        setApp: _setApp,
        userProperties,
        setUserProperties: _setUserProperties,
        cookieConsent,
        setCookieConsent: _setCookieConsent
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}
